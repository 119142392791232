import React, { useState } from 'react';
import Switch from '@mui/material/Switch';
import { MuiTypography, MuiList, MuiListSubheader, MuiListItem, MuiListItemText } from 'components/MUI';

export default function SwitchList(props) {

    const { listname, items, keyName, onChange, disabled, selectedValues } = props;

    const handleChangeSwitch = (e, item) => {
        if (onChange) {
            onChange(e, item);
        }
    }

    return (
        <MuiList
            subheader={
                <MuiListSubheader>
                    <MuiTypography variant="h3">{listname}</MuiTypography>
                </MuiListSubheader>
            }
            className='switchListWrap'
            sx={{ width: '100%' }}
        >
            {items?.length > 0 && items.map((item, key) => (
                <MuiListItem key={key}>
                    <MuiListItemText primary={item[keyName]} />
                    <Switch disabled={disabled} checked={selectedValues.includes(item.id)} onChange={(e) => handleChangeSwitch(e, item)} />
                </MuiListItem>
            ))}
        </MuiList>
    );
}