import React, { useCallback, useEffect, useState } from 'react'
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from "@stripe/react-stripe-js";
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setPaymentStatus, setResetCard, setResetPlan } from 'redux/Actions/subscription';
import { setUpdateUserStatus } from 'redux/Actions/login';
import { UserStatus } from 'redux/Reducers/login';
import { MuiBox, MuiGrid, MuiTypography, MuiInputField } from 'components/MUI';
import Theme from 'Theme';
import { required, whiteSpaceNotAllowedFirstLetter } from 'validators';
import { setReloadPage } from 'redux/Actions/ui';

const appearance = {
    theme: 'stripe',
    variables: {
        colorPrimary: '#0570de',
        colorBackground: '#ffffff',
        colorText: '#30313d',
        colorDanger: '#df1b41',
        fontFamily: 'Ideal Sans, system-ui, sans-serif',
        spacingUnit: '2px',
        borderRadius: '4px',
    }
};

function PaymentForm(props) {

    const history = useHistory();
    const dispatch = useDispatch();
    const { clientSecret, isSubmitForm, isSubmitFormFunc, loadModalFunc } = props;

    const stripe = useStripe();
    const elements = useElements(appearance);
    const [paymentErr, setPaymentErr] = useState(null);
    const [name, setName] = useState('');
    const [error, setError] = useState({});

    useEffect(() => {
        if (isSubmitForm && clientSecret) handleSubmit();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [isSubmitForm]);

    const handleSubmit = async () => {
        if (!stripe || !elements) {
            return
        };
        // Confirm the payment intent using the client secret
        setError({});
        if (required(name) || whiteSpaceNotAllowedFirstLetter(name)) {
            isSubmitFormFunc(false);
            loadModalFunc(false);
            setError((prevState) => ({ ...prevState, name: "Your name is required. The first letter cannot be a space" }));
            return;
        }
        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    name: name
                }
            }
        });

        if (!payload.error) {
            history.replace('/payment/success')
            dispatch(setPaymentStatus('SUCCESS'));
            dispatch(setResetPlan());
            dispatch(setResetCard());
            dispatch(setUpdateUserStatus(UserStatus.VERIFIED));
            // dispatch(setReloadPage(true));
        } else if (payload.error.type === 'validation_error') {
            const { error: { message } } = payload;
            loadModalFunc(false);
            isSubmitFormFunc(false);
            setPaymentErr(message);
            return;
        } else {
            dispatch(setPaymentStatus('FAILED'));
            history.replace('/payment/failed');
            dispatch(setResetPlan());
            dispatch(setResetCard());
        }
    };

    const handleError = (e) => {
        if (e.error) {
            isSubmitFormFunc(false);
            loadModalFunc(false);
        }
    }

    return (
        <MuiBox>
            <MuiGrid container spacing={2}>
                <MuiGrid item xs={12} sx={{
                    '& .MuiFormControl-root': {
                        marginBottom: 0,
                        '& input': {
                            fontSize: 16,
                            '&:placeholder': {
                                color: '#0000008a',
                            }
                        }
                    }
                }}>
                    <MuiInputField
                        onChange={(e) => setName(e.target.value)}
                        name="name"
                        type='text'
                        placeholder='Name on card'
                        value={name}
                        error={Boolean(error.name)}
                        helperText={error.name || ""}
                    />
                </MuiGrid>
                <MuiGrid item xs={12}>
                    <MuiBox className='stripeInputField'>
                        <CardNumberElement onChange={handleError} />
                    </MuiBox>
                </MuiGrid>
                <MuiGrid item sm={6} xs={12}>
                    <MuiBox className='stripeInputField'>
                        <CardExpiryElement onChange={handleError} />
                    </MuiBox>
                </MuiGrid>
                <MuiGrid item sm={6} xs={12}>
                    <MuiBox className='stripeInputField'>
                        <CardCvcElement onChange={handleError} />
                    </MuiBox>
                </MuiGrid>
                <MuiGrid item xs={12}>
                    {paymentErr && <MuiTypography variant='body3' sx={{
                        color: Theme.palette.error.main
                    }}>{paymentErr}</MuiTypography>}
                </MuiGrid>
            </MuiGrid>
        </MuiBox>
    )
}

export default PaymentForm