export const SELECT_PLAN = 'SELECT_PLAN';
export const RESET_PLAN = 'RESET_PLAN';
export const ADD_STATUS = 'ADD_STATUS';
export const ADD_CARD = 'ADD_CARD';
export const RESET_CARD = 'RESET_CARD';

export const setSelectPlan = (data) => {
    return {
        type: SELECT_PLAN,
        payload: data
    }
}

export const setResetPlan = () => {
    return {
        type: RESET_PLAN,
    }
}

export const setPaymentStatus = (data) => {
    return {
        type: ADD_STATUS,
        payload: data
    }
}

export const setAddCard = (data) => {
    return {
        type: ADD_CARD,
        payload: data
    }
}

export const setResetCard = () => {
    return {
        type: RESET_CARD,
    }
}