import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import styles from 'global-styles';
import { MuiBox, MuiButton, MuiDivider, MuiIconButton, MuiInputField, MuiTypography,MuiCustomLink } from 'components/MUI';
import { CloseIcon } from 'helpers/Icons';
import CreateNgoModal from 'components/Modal/CreateNgoModal';
function OrderSummary(props) {
    const { onCouponApply, coupon, summary, classes, whiteLableChecked } = props;

    const [couponId, setCouponId] = useState('');
    const [ngoModal, setNgoModal] = useState(false)
    const handleCouponClear = () => {
        if (coupon) onCouponApply('');
        else if (couponId && !coupon) setCouponId('');
    }

    useEffect(() => {
        setCouponId(coupon);
    }, [coupon])

    return (
        <MuiBox className={classes.paymentPagesMain}>
            {summary &&
                <>
                    <MuiTypography variant='h4' mb={1.5}>Order summary</MuiTypography>

                    <MuiBox className='lightBlueBox'>
                        <MuiTypography variant='body3' mb={2}>{summary?.planName || ''}</MuiTypography>
                        <MuiDivider sx={{ my: 2 }} />

                        <MuiTypography variant='body3' mb={2}>Package Price:</MuiTypography>
                        <MuiTypography variant='h5'>
                            € {summary.pkgPrice}/{summary.interval}
                        </MuiTypography>

                        <MuiDivider sx={{ my: 2 }} />

                        {summary.whiteLabelPrice && summary.whiteLabelPrice >0 ?
                            <>
                                <MuiTypography variant='body3' mb={2}>Whitelabel Price:</MuiTypography>
                                <MuiTypography variant='h5'>
                                    € {summary.whiteLabelPrice}/{summary.interval}
                                </MuiTypography>

                                <MuiDivider sx={{ my: 2 }} />
                            </>
                            : null
                        }

                        <MuiTypography variant='h5' mb={1.5}>Apply coupon code</MuiTypography>
                        <MuiBox mb={2} className='applyCouponBox'>
                            <MuiInputField
                                sx={{ backgroundColor: '#fff' }}
                                onChange={e => setCouponId(e.target.value)}
                                name="coupon"
                                value={couponId}
                                type='text'
                                placeholder='Enter Coupon Code'
                                required
                                iconEnd={couponId &&
                                    <>
                                        <MuiIconButton onClick={handleCouponClear} sx={{ mr: 1 }}>
                                            <CloseIcon />
                                        </MuiIconButton>
                                        <MuiButton variant="text" background="false" onClick={() => onCouponApply(couponId)}>
                                            Apply
                                        </MuiButton>
                                    </>
                                }
                            />
                        </MuiBox>
                        {summary.discountPrice ?
                            <MuiBox display='flex' sx={{

                            }}>
                                <MuiTypography component='p' mr={1} variant='body4'>Discount Price: </MuiTypography>
                                <MuiTypography variant='h6'>
                                    € {summary.discountPrice}/{summary.interval}
                                </MuiTypography>
                            </MuiBox>
                            : null
                        }
                        <MuiDivider sx={{ my: 2 }} />
                        <MuiBox sx={{
                                    '& p': {
                                        margin: '8px 0 0 0',
                                        fontSize: '15px',
                                    },
                                    '& a': {
                                        marginLeft: 5
                                    },
                                }}>
                                    <p>
                                        Are you an NGO?&nbsp;
                                        <MuiCustomLink to="#" onClick={() => setNgoModal(true)}>
                                            <b>Click here</b>
                                        </MuiCustomLink>
                                    </p>
                        </MuiBox>
                        <MuiDivider sx={{ my: 2 }} />
                        {summary.taxablePrice && summary.taxablePrice > 0 ? <>    
                            <MuiTypography variant='body3' mb={2}>VAT Price:</MuiTypography>
                            <MuiTypography variant='h5'>
                                € {summary.taxablePrice}/{summary.interval}
                            </MuiTypography>

                            <MuiDivider sx={{ my: 2 }} />
                        </> : null}
                        <MuiTypography variant='body3' mb={2}>Order Total:</MuiTypography>
                        <MuiTypography variant='h5'>
                            € {summary.totalPrice}/{summary.interval}
                        </MuiTypography>

                        <MuiDivider sx={{ my: 2 }} />

                        <MuiTypography variant='body3' mb={2}>Total Payable:</MuiTypography>
                        <MuiTypography variant='h5'>
                            € {summary.payablePrice}/{summary.interval}
                        </MuiTypography>

                        <MuiDivider sx={{ my: 2 }} />

                    </MuiBox>
                </>
            }
            {
                ngoModal && <CreateNgoModal
                    closeModal={() => setNgoModal(false)}
                    createNgoModalOpen={ngoModal}
                />
            }
        </MuiBox>
    )
}

OrderSummary.propTypes = {
    classes: PropTypes.any,
};

export default withStyles(styles)(OrderSummary);