export const API_CALL_PROGRESS = 'API_CALL_PROGRESS';
export const SHOULD_RELOAD_PAGE = 'SHOULD_RELOAD_PAGE';

export const setApiCallProgress = (data) => {
    return {
        type: API_CALL_PROGRESS,
        payload: data
    }
}

export const setReloadPage = (data) => {
    return {
        type: SHOULD_RELOAD_PAGE,
        payload: data
    }
}