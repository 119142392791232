import { ADD_CARD, ADD_STATUS, RESET_CARD, RESET_PLAN, SELECT_PLAN } from 'redux/Actions/subscription'


const initialState = {
    card: {},
    paymentStatus: "",
    plan: {}
};

const Subscription = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case SELECT_PLAN:
            return {
                ...state,
                plan: { ...state.plan, ...payload }
            }
        case RESET_PLAN:
            return {
                ...state,
                plan: initialState.plan
            }
        case ADD_STATUS:
            return {
                ...state,
                paymentStatus: payload
            }
        case ADD_CARD:
            return {
                ...state,
                card: { ...state.card, ...payload }
            }
        case RESET_CARD:
            return {
                ...state,
                card: initialState.card
            }
        default:
            return state;
    }
}

export default Subscription;