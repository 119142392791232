import { Spinner } from 'Assets/Images'
import React from 'react'

function Loader() {
    return (
        <div className='appLoader'>
            <img src={Spinner} alt='loader' />
        </div>
    )
}

export default Loader