import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { Map } from 'helpers';
import { styled } from '@mui/material/styles';
import styles from 'global-styles';
import { MuiBox, MuiCssBaseline, MuiAppBar, MuiToolbar } from 'components/MUI';
import { Header, Loader } from 'components';
import AuthService from 'services/AuthService';
import { setUpdateUserProfile, addNotification, updateDomainLoader } from 'redux/Actions/login';
import { AppRoutes } from 'routes/Application';
import Pusher from 'pusher-js';
import { API_URL, PUSHER_KEY, PUSHER_CLUSTER } from "../configs"

const drawerWidth = 270;

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '5px 10px',
    ...theme.mixins.toolbar,
}));

function Application(props) {

    const { classes, paymentStatus, isApiCallProgress, dispatch,user,token } = props;


    const [mobileOpen, setMobileOpen] = useState(false);

    const location = useLocation();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    useEffect(() => {
        getMe();
        const channel = pusherNotification();
        return () => {
            channel.unbind_all();
            channel.unsubscribe();
        }
    }, [])

    const getMe = () => {
        AuthService.getMe().then((resp) => {
            dispatch(setUpdateUserProfile(resp.data));
        }).catch((err) => err);
    }

    const pusherNotification = () => {
        // Pusher.logToConsole = true;
        const pusher = new Pusher(PUSHER_KEY, {
            encrypted: true,
            cluster: PUSHER_CLUSTER,
            authEndpoint: `${API_URL}/broadcasting/auth`,
            auth: {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        });
        const host = process.env.NODE_ENV == 'development' ? 'vockam.bert' : window.location.host
        const channel = pusher.subscribe(`private-user.${host}.${user.id}`);
        channel.bind("Illuminate\\Notifications\\Events\\BroadcastNotificationCreated", function (data) {
            if(data.message){
                const {id,type,...rest} = data;
                if(type == "App\\Notifications\\DomainCreatedNotification"){
                    dispatch(updateDomainLoader(false));
                    if (window.confirm("A new domain has been created. Do you want to open it?")) {
                        window.open(rest.domainUrl, "_blank");
                    }
                    getMe();
                }
                addNotification({id,type,data:{...rest}});
            }
        });
        return channel;
    }


    return (
        <>
            {isApiCallProgress && <Loader />}
            <MuiBox className={classes.dashboardLayoutMainWrap} sx={{ display: 'flex' }}>
                <MuiCssBaseline />
                <MuiAppBar
                    className='dashboardHeaderWrap'
                    position="fixed"
                    sx={{
                        width: { md:'100%'  },
                        ml: { md: `${drawerWidth}px` },
                        transition: '0.4s',
                    }}
                >
                    <MuiToolbar>
                        <Header handleDrawerToggle={handleDrawerToggle} open={mobileOpen} />
                    </MuiToolbar>
                </MuiAppBar>
                {/* end header */}

                <MuiBox component="main" className='dashboardLayoutMain'
                    sx={{
                        flexGrow: 1,
                        maxWidth: { md: mobileOpen || location.pathname === '/plans' ? '100%' : `calc(100% - ${drawerWidth}px)` },
                        width: { xs: '100%', md: mobileOpen ? `calc(100% - ${drawerWidth}px)` : '100%' },
                        transition: '0.4s',
                    }}
                >
                    <MuiBox className='dashboardContentMainWrap'>
                        <DrawerHeader />

                        <Switch>
                            {Map(AppRoutes, (route, routeIndex) => (
                                <Route key={routeIndex} path={route.path} exact={route.exact}
                                    render={(rProps) => <route.component {...props} {...rProps} />} />
                            ))}
                            <Redirect exact to={paymentStatus ? `/payment/${paymentStatus.toLowerCase()}` : "/plans"} />
                        </Switch>
                    </MuiBox>
                </MuiBox>
            </MuiBox>
        </>
    );
}

Application.propTypes = {
    classes: PropTypes.any,
};

export default withStyles(styles)(Application);
