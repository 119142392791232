import { FormControl, Select } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles';
import { MuiFormHelperText, MuiMenuItem } from 'components/MUI';
import React from 'react';
import styles from './muiStyles-jss';
import { GetValue, Map } from 'helpers';
import Theme from 'Theme';


const MuiSelectDropdown = (props) => {

  const { items, placeholder, titlefield = 'label', valuefield = 'value', clear = false, msg, notDataMessage = 'No Data Found' } = props;

  const classes = useStyles();
  return (
    <FormControl fullWidth className={classes.commonSelectDropdown} error={!!msg}>
      <Select variant="outlined" {...props} msg={msg} >
        {placeholder &&
          <MuiMenuItem value="" selected disabled>{placeholder}</MuiMenuItem>}
        {items && items.length > 0 ? Map(items, (option, key) => (
          <MuiMenuItem value={GetValue(option, valuefield)} key={key}>
            {option.icon && <option.icon style={{ margin: '0 8px 0 0' }} />} {GetValue(option, titlefield) || ''}
          </MuiMenuItem>
        )) : <MuiMenuItem disabled>{notDataMessage}</MuiMenuItem>}
      </Select>
      {msg && <MuiFormHelperText> {msg} </MuiFormHelperText>}
    </FormControl>
  );
}

const useStyles = makeStyles({
  commonSelectDropdown: {
    '& .MuiInputBase-root': {
      '&::after': {
        display: 'none'
      },
      '&:hover': {
        '& fieldset': {
          borderColor: 'rgba(0, 0, 0, 0.23)',
        }
      },
      '&.Mui-focused': {
        '& fieldset': {
          borderWidth: 1,
        }
      }
    },
    '& .MuiInput-root': {
      borderColor: 'rgba(0, 0, 0, 0.23)',
      borderRadius: '5px',
    },
    '& .MuiSelect-root, .MuiSelect-select': {
      padding: '12px 15px',
      paddingRight: 30,
      background: Theme.palette.secondary.main,
    },
    '& .selectClearButton': {
      position: 'absolute',
      right: 5,
      top: '50%',
      transform: 'translate(0, -50%)',
      background: Theme.palette.secondary.main,
      width: 25,
      padding: 5,
      minWidth: 25,
      height: 25,
    }
  },
});

export default withStyles(styles)(MuiSelectDropdown);