import { toast } from 'react-toastify';

export default class ToastService {

    static success(msg) {
        toast.success(msg, { toastId: msg });
    }

    static error(msg) {
        toast.error(msg, { toastId: msg });
    }

    static warning(msg) {
        toast.warn(msg, { toastId: msg });
    }

    static info(msg) {
        toast.info(msg, { toastId: msg })
    }

    static question(msg) {
        toast(msg, { toastId: msg })
    }

    static customError(component) {
        toast.error(component, { autoClose: 4000 })
    }
}