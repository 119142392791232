import { Http } from "Http";

export default class SubscriptionService {
    static getAllPlans(params = {}) {
        return new Promise((resolve, reject) => {
            Http.get('/subscription/products', params).then((resp) => {
                return resolve(resp.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    static calculateSubscription(params = {}) {
        return new Promise((resolve, reject) => {
            Http.post('/subscription/calculate-price', params).then((resp) => {
                return resolve(resp.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    static charge(params = {}) {
        return new Promise((resolve, reject) => {
            Http.post('/subscription/charge', params).then((resp) => {
                return resolve(resp.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    static getCardDetails(params) {
        return new Promise((resolve, reject) => {
            Http.get('/subscription/card', params).then((resp) => {
                return resolve(resp.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    static createCard(params = {}) {
        return new Promise((resolve, reject) => {
            Http.post('/subscription/card', params).then((resp) => {
                return resolve(resp.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    static productInfo(priceId) {
        return new Promise((resolve, reject) => {
            Http.get(`/subscription/plan-info/${priceId}`).then((resp) => {
                return resolve(resp.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    static mySubscription() {
        return new Promise((resolve, reject) => {
            Http.get('/subscription/my-subscription').then((resp) => {
                return resolve(resp.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    static cancelSubscription(params) {
        return new Promise((resolve, reject) => {
            Http.post('/subscription/cancel', params).then((resp) => {
                return resolve(resp.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    static activeFreePlan() {
        return new Promise((resolve, reject) => {
            Http.post('/subscription/activate-free-package').then((resp) => {
                return resolve(resp.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    static activeTrialPlan(priceId) {
        return new Promise((resolve, reject) => {
            Http.post(`/subscription/trial/${priceId}`).then((resp) => {
                return resolve(resp.data);
            }).catch((err) => {
                return reject(err);
            })
        })
    }

    static sendRequestForNgoCoupon(params) {
        return new Promise((resolve, reject) => {
            Http.post(`/subscription/request-ngo-coupon`,params).then(res => {
                return resolve(res.data);
            }).catch(err => {
                return reject(err);
            });
        });
    }
}