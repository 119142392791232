import * as React from 'react';
import { styled } from '@mui/material/styles';
import { MuiBox, MuiDialog, MuiDialogContent, MuiTypography } from 'components/MUI';
import { AccessTimeOutlinedIcon } from 'helpers/Icons';

const CustomDialog = styled(MuiDialog)(({ theme }) => ({
    '&.MuiDialog-root': {
        zIndex: 99999
    },
    '& .MuiDialogContent-root': {
        padding: 40,
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(2),
        '& button': {
            minWidth: 100,
        }
    },
    '& .MuiPaper-root': {
        minWidth: 300,
    },
}));

function LoadingModal(props) {
    const { handleClose, open } = props;

    const closeModal = () => {
        if (handleClose) handleClose();
    }

    return (
        <CustomDialog
            onClose={closeModal}
            aria-labelledby="dialog-title"
            scroll='body'
            open={open}
        >
            <MuiDialogContent>
                <MuiBox align='center' sx={{
                    '& svg': {
                        fontSize: 80,
                        color: '#000000bf',
                    }
                }}>
                    <AccessTimeOutlinedIcon />
                    <MuiTypography variant='h4' mt={1.5}>Don't refresh the page and don't click anything</MuiTypography>
                </MuiBox>
            </MuiDialogContent>
        </CustomDialog>
    );
}

export default LoadingModal;