import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import Theme from 'Theme';
import { MuiDialogContent } from 'components/MUI';

export const MainNoGap = styled(Box)(() => ({
  padding: 0,
  position: 'relative',
  margin: '-22px 0 0 -20px',
  width: 'calc(100% + 40px)',
  [Theme.breakpoints.down(600)]: {
    margin: '-40px 0 0 -20px',
  },
}));

export const ContentGap = styled(Box)(() => ({
  padding: 20,
}));

export const TopHeading = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: 20,
  [Theme.breakpoints.down(992)]: {
    display: 'block',
  },
  '& .headingBtnGroup': {
    [Theme.breakpoints.down(992)]: {
      marginTop: 15,
      flexWrap: 'wrap',
    },
  },
  '& button': {
    margin: 8,
    '&:last-of-type': {
      marginRight: 0,
    },
    [Theme.breakpoints.down(992)]: {
      margin: '8px 12px 8px 0',
    },
  },

}));

export const DataTableWrap = styled(Box)(() => ({
  '& table': {
    borderSpacing: '0 12px',
    padding: '0 10px',
  },
  '& .thContent': {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      fontSize: 20,
      marginLeft: 10,
    },
    '& button': {
      height: 22,
      width: 22,
      background: Theme.palette.primary.main,
      color: Theme.palette.secondary.dark,
      padding: 5,
      marginLeft: 8,
      '& svg': {
        marginLeft: 0,
      },
    }
  },
  '& th': {
    padding: '10px 15px',
    '& .MuiTypography-root': {
      color: Theme.palette.secondary.dark,
      whiteSpace: 'nowrap',
      '& span': {
        color: Theme.palette.text.light,
        marginTop: 4,
        display: 'block',
      }
    }
  },
  '& td': {
    padding: 15,
    position: 'relative',
    '& .MuiTypography-root': {
      color: Theme.palette.secondary.dark,
      '& svg': {
        width: 17,
        lineHeight: 0,
        height: 'auto',
        verticalAlign: 'bottom',
      },
      '& span': {
        color: Theme.palette.text.light,
        marginTop: 4,
        display: 'block',
      }
    }
  },
  '& tr': {
    transition: '0.4s',
    borderRadius: '5px',
    '&:hover': {
      boxShadow: '0 0 10px #00000029',
      transition: '0.4s',
      '& td': {
        borderColor: 'transparent',
        '& .MuiTypography-root': {
          '& span': {
            color: Theme.palette.primary.main,
            transition: '0.4s',
          }
        },
      },
    }
  },
  '& thead': {
    '& tr': {
      '&:hover': {
        boxShadow: 'unset',
      }
    },
  },
  '& .MuiPaper-root': {
    boxShadow: 'none',
  },
  '& .tdYellowBox': {
    border: '2px solid' + Theme.palette.primary.main,
    borderRadius: '6px',
    background: '#FFF8ED',
    padding: 12,
    margin: '8px 0',
    '& .tdYellowBxHdng': {
      color: Theme.palette.primary.main + '!important',
      whiteSpace: 'nowrap',
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
      '& span': {
        color: Theme.palette.text.main + '!important',
        margin: '0 0 0 12px',
      }
    },
    '& ul': {
      padding: 0,
      '& li': {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '5px 0',
        '& p': {
          color: Theme.palette.secondary.dark + '!important',
        },
        '& span': {
          color: Theme.palette.primary.main + '!important',
        }
      }
    }
  }
}));

export const CommonContent = styled(MuiDialogContent)(() => ({
  '& .fieldMainWrap': {
    '& label': {
      fontSize: 15,
      margin: '0 0 8px 5px',
      display: 'block',
      fontWeight: 500,
      fontFamily: "'Open Sans', sans-serif",
      [Theme.breakpoints.up(1500)]: {
        fontSize: 17,
      },
      [Theme.breakpoints.up(1700)]: {
        fontSize: 20,
      },
    },
    '& .MuiFormControl-root': {
      marginBottom: 0,
    },
    '& .MuiInputBase-root': {
      '& input': {
        fontSize: 16,
        background: Theme.palette.secondary.main,
      },
      '& fieldset': {
        border: '1px solid #dedede',
      },
      '&:hover': {
        '& input': {
          fontSize: 16,
        },
        '& fieldset': {
          border: '1px solid #dedede',
        }
      },
      '&.Mui-focused': {
        '& input': {
          boxShadow: '0 0 10px #0000000d',
        },
        '& fieldset': {
          border: '1px solid #dedede',
          borderWidth: 1,
        }
      },
      '&.Mui-error': {
        '& fieldset': {
          border: `1px solid ${Theme.palette.error.main}`,
        }
      }
    },
  },
}));