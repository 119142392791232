import React from 'react';
import { TextField, InputAdornment } from "@mui/material";
import { MuiBox } from 'components/MUI';

const MuiInputField = (props) => {

	const { iconStart, iconEnd, helperText, sx, ...rest } = props;

	return (
		<MuiBox className='textFieldMainWrap' sx={{
			'& .MuiInputAdornment-positionStart': {
				margin: '0 12px 0 0',
			},
			'& .MuiOutlinedInput-root': {
				borderRadius: '5px',
			},
			'& input': {
				padding: iconStart ? '12px 15px 12px 0' : '12px 15px',
			},
			'& .MuiInputBase-root:hover fieldset': {
				borderColor: 'rgba(0, 0, 0, 0.23) !important'
			},
			'& div:hover fieldset': {
				borderColor: 'rgba(0, 0, 0, 0.23) !important'
			},
			'& .MuiInputBase-root': {
				'&.Mui-focused': {
					'& fieldset': {
						borderWidth: 1,
					}
				}
			},
		}}>
			<TextField
				{...rest}
				fullWidth={true}
				variant="outlined"
				helperText={helperText ? helperText : ''}
				InputProps={{
					startAdornment: iconStart ? (
						<InputAdornment position="start">
							{iconStart}
						</InputAdornment>
					) : null,
					endAdornment: iconEnd ? (
						<InputAdornment position="end">
							{iconEnd}
						</InputAdornment>
					) : null,
				}}
			/>
		</MuiBox>
	);
}

export default MuiInputField;
