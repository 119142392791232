
import { configureStore } from '@reduxjs/toolkit';
import Stroage from './Storage';
import Reducers from './Reducers/RootReducer';

const Store = configureStore({
    reducer: Reducers,
    preloadedState: Stroage.LoadState(),
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
            immutableCheck: false
        })
});

Store.subscribe(() => {
    Stroage.SaveState(Store.getState());
});

export default Store;