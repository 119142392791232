import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Theme from 'Theme';

const MuiCheckbox = (props) => {

    const { label, onChange, checked, ...rest } = props;

    return (
        <FormGroup sx={{
            '& .MuiFormControlLabel-root': {
                width: 'fit-content',
                marginRight: 0,
                '& .MuiTypography-root': {
                    color: Theme.palette.secondary.light
                }
            }
        }}>
            <FormControlLabel control={<Checkbox onChange={onChange} />} label={label} checked={checked} {...rest} />
        </FormGroup>
    );
}

export default MuiCheckbox;