import { API_CALL_PROGRESS, SHOULD_RELOAD_PAGE } from "redux/Actions/ui";

const initialState = {
    isApiCallProgress: false,
    shouldReloadPage: false
};

const Ui = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case API_CALL_PROGRESS:
            return {
                ...state,
                isApiCallProgress: payload
            }
        case SHOULD_RELOAD_PAGE:
            return {
                ...state,
                shouldReloadPage: payload
            }
        default:
            return state;
    }
}

export default Ui;