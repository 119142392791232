
import React from 'react';
import { MuiBox } from 'components/MUI'

export default function MuiTabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<MuiBox
			role="tabpanel"
			hidden={value !== index}
			id={`scrollable-auto-tabpanel-${index}`}
			aria-labelledby={`scrollable-auto-tab-${index}`}
			{...other}
		>
			{value === index && (
				<MuiBox>
					{children}
				</MuiBox>
			)}
		</MuiBox>
	);
}