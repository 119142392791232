import { createStyles } from '@mui/styles';
import Theme from './Theme';
import { AuthRightBg, BlackDot, ProfileBanner } from 'Assets/Images';

const mulish = "'Mulish', sans-serif"; // Mulish Font
const openSans = "'Open Sans', sans-serif"; // Open sans Font

const headerHeight = 65;
const mobileHeaderHeight = 58;
const drawerWidth = 270;

const themePallete = Theme.palette;

const styles = () => createStyles({
  '@global': {
    a: {
      color: 'inherit',
      textDecoration: 'none',
    },
    body: {
      padding: 0,
      margin: 0,
      fontFamily: mulish,
      [Theme.breakpoints.up('md')]: {
        overflow: 'auto !important',
        paddingRight: '0 !important',
      },
      '& .MuiFormHelperText-root': {
        marginLeft: 0,
      },
      // **** start sidebar css ****
      '& .sidebarLogoWraper': {
        '& .sidebarLogo': {
          '& img': {
            width: 'auto',
            maxWidth: '100%',
            maxHeight: 25,
          },
        },
      },
      '& .sidebarCollapseBtn': {
        width: 40,
        height: 40,
        '& img': {
          width: 22,
        }
      },
      '& .sidebarListMainWraper': {
        padding: '15px 15px 55px 15px',
        overflowY: 'auto',
        '& .MuiListItem-root': {
          padding: '5px 12px',
          borderRadius: '15px',
          cursor: 'pointer',
          marginBottom: 8,
          transition: '0.3s',
          '&:hover': {
            backgroundColor: themePallete.primary.contrastText,
            transition: '0.3s',
          },
          '&.hasSubMenu': {
            backgroundColor: '#fbce1724',
          },
          '& .sidebarColapseIcon': {
            '& svg': {
              fontSize: 20,
            }
          },
          '&.Mui-selected': {
            backgroundColor: themePallete.primary.main,
            '& span': {
              color: themePallete.secondary.dark,
              fontWeight: 500,
            },
            '& .MuiListItemIcon-root': {
              '& svg': {
                color: themePallete.secondary.dark,
              }
            },
          },
          '& .MuiListItemIcon-root': {
            minWidth: 38,
            '& svg': {
              fontSize: 20,
              color: themePallete.text.light,
            }
          },
          '& span': {
            fontSize: 15,
            color: '#000000de',
            fontWeight: 400,
            whiteSpace: 'pre-line',
          },
        },
        '& .submenuMainWrap': {
          '& ul': {
            paddingBottom: 10,
            '& li': {
              '&.Mui-selected': {
                backgroundColor: 'transparent',
                '& span': {
                  color: themePallete.error.contrastText,
                },
              },
            }
          }
        }
      },
      // **** end sidebar css ****

    },
    p: {
      '& a': {
        underline: 'none',
        display: 'inline-block',
        fontWeight: 700,
        textTransform: 'inherit',
        color: themePallete.secondary.dark,
        fontFamily: mulish,
        fontSize: 16,
        transition: '0.5s',
        '&:hover': {
          color: themePallete.primary.main,
          transition: '0.5s',
        },
        [Theme.breakpoints.up('xl')]: {
          fontSize: 20,
        },
        [Theme.breakpoints.down('md')]: {
          fontSize: 16,
        },
      }
    }
  },

  authPageMainWrap: {
    '& .forgotPasswordView': {
      '& .authHeading': {
        margin: '60px 0',
        [Theme.breakpoints.down('sm')]: {
          margin: '25px 0 30px 0',
        },
      },
    },
    '& .authLeftMain': {
      padding: '40px 0',
      width: '75%',
      margin: '0 auto',
      '& .authLogoWrap': {
        '& img': {
          width: 250
        }
      },
      '& .authHeading': {
        margin: '40px 0',
        [Theme.breakpoints.down('sm')]: {
          margin: '25px 0 30px 0',
        },
      },
      [Theme.breakpoints.down('sm')]: {
        width: 'calc(100% - 60px)',
        padding: '30px',
      },
    },
    '& .authFormWrap': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      '& .socialLoginOptionsWrap': {
        '& .socialBtn': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: '#000',
          fontWeight: 600,
          textAlign: 'left',
          fontSize: 14,
          background: '#fff',
          padding: '8px 12px',
          borderRadius: '5px',
          cursor: 'pointer',
          transition: '0.4s',
          border: '1px solid #cfcfcf',
          '&:hover': {
            backgroundColor: '#cfcfcf',
            border: '1px solid #cfcfcf',
            transition: '0.4s',
          },
          '& img': {
            width: 20,
            marginRight: 8,
          },
        },
      },
      '& .formFieldLabel': {
        fontSize: 16,
        margin: '0 0 5px 15px',
        fontFamily: openSans,
        display: 'block',
        [Theme.breakpoints.up('xl')]: {
          fontSize: 18
        },
        [Theme.breakpoints.up(1700)]: {
          fontSize: 20
        },
      },
      '& .MuiButton-endIcon': {
        border: 'none'
      },
      '&.loginView': {
        '& .authHeading': {
          margin: '30px 0',
          '& h1': {
            fontSize: 25,
          },
        },
        '& .textFieldMainWrap': {
          '& .MuiFormControl-root': {
            marginBottom: 0,
          }
        }
      },
    },
    '& .authRightContetMain': {
      position: 'sticky',
      top: 0,
      right: 0,
      backgroundColor: themePallete.primary.main,
      backgroundImage: `linear-gradient(180deg, rgba(255,255,255,0.41220238095238093) -6%, rgba(193,193,193,0) 20%, rgb(0 0 0 / 51%) 79%), url(${AuthRightBg})`,
      padding: 60,
      height: 'calc(100vh - 120px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '--bs-topImgHeight': '60px',
      '& .roundLogo': {
        position: 'absolute',
        left: 0,
        top: 0,
        right: 0,
        margin: '0 auto',
        height: 'var(--bs-topImgHeight)',
      },
      '& .content': {
        marginTop: 'var(--bs-topImgHeight)',
        maxWidth: 400,
        margin: '0 auto',
        [Theme.breakpoints.up('xl')]: {
          maxWidth: 500,
        }
      },
      '& .whiteBox': {
        boxShadow: '0 10px 38px 3px rgb(9 8 7 / 32%)',
        borderRadius: '20px',
        backgroundColor: themePallete.secondary.main,
        padding: 25,
        display: 'flex',
        minHeight: 250,
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '& .quoteIcon': {
          width: 12,
          filter: 'invert(1)',
          margin: '-10px 8px 0 0',
          '&.last': {
            margin: '0 0 -10px 8px',
            transform: 'rotate(180deg)',
          }
        },
      }
    },
    '& .otpBoxWrap': {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridGap: 30,
      [Theme.breakpoints.up('xl')]: {
        gridGap: 50,
      },
      [Theme.breakpoints.up(1800)]: {
        gridGap: 70,
      },
      [Theme.breakpoints.down(400)]: {
        gridGap: 15,
      },
      '& .textFieldMainWrap': {
        aspectRatio: 1,
        '& .MuiFormControl-root': {
          width: '100%',
          height: '100%',
          margin: 0,
        },
        '& .MuiInputBase-root': {
          width: '100%',
          height: '100%',
          margin: 0,
          borderRadius: '12px',
          [Theme.breakpoints.down('md')]: {
            borderRadius: '8px',
          },
        },
        '& input': {
          height: 'calc(100% - 30px)',
          padding: 15,
          textAlign: 'center',
          fontSize: 22,
          color: themePallete.secondary.dark,
        }
      }
    },
  },

  // ========================== Start Dashboard Layouts css ==========================
  dashboardLayoutMainWrap: {
    minHeight: '100vh',
    width: '100%',
    backgroundColor: themePallete.secondary.contrastText,
    // **** start common css ****
    '& .userMenuMainWrap': {
      '& .MuiAvatar-root': {
        backgroundColor: themePallete.primary.main,
        color: themePallete.secondary.dark,
      }
    },
    '& .commonDropMenuWrap': {
      '& ul': {
        '& li': {
          padding: '2px 15px',
          fontSize: 15,
          color: themePallete.primary.dark,
          '& svg': {
            color: themePallete.primary.dark,
          }
        }
      }
    },
    '& .whiteBoxWrap': {
      background: themePallete.primary.contrastText,
      padding: 20,
      borderRadius: '5px',
      boxShadow: '0 4px 16px 0 rgb(0 0 0 / 4%)',
      marginBottom: 15,
    },
    '& .commonTableWrap': {
      overflowY: 'auto',
      '& table': {
        width: 'max-content',
        minWidth: '100%',
        '& th, td': {
          color: themePallete.primary.dark,
          textAlign: 'left',
          padding: '10px 15px',
          borderBottom: '1px solid #dedede',
          fontSize: 15,
          '&:first-child': {
            paddingLeft: 5,
          },
          '&:last-child': {
            paddingRight: 5,
          },
        },
        '& th': {
          fontWeight: 500,
        },
        '& tr': {
          '&:last-child': {
            '& td': {
              borderBottom: 'none',
            },
          }
        }
      }
    },
    '& .fieldMainWrap': {
      '& label': {
        fontSize: 15,
        margin: '0 0 8px 5px',
        display: 'block',
        fontWeight: 500,
        fontFamily: openSans,
        [Theme.breakpoints.up(1500)]: {
          fontSize: 17,
        },
        [Theme.breakpoints.up(1700)]: {
          fontSize: 20,
        },
      },
      '& .MuiFormControl-root': {
        marginBottom: 0,
      },
      '& .MuiInputBase-root': {
        '& input': {
          fontSize: 16,
        },
        '& fieldset': {
          border: '1px solid #dedede',
        },
        '&:hover': {
          '& input': {
            fontSize: 16,
          },
          '& fieldset': {
            border: '1px solid #dedede',
          }
        },
        '&.Mui-focused': {
          '& input': {
            boxShadow: '0 0 10px #0000000d',
          },
          '& fieldset': {
            border: '1px solid #dedede',
            borderWidth: 1,
          }
        },
        '&.Mui-error': {
          '& fieldset': {
            border: `1px solid ${themePallete.error.main}`,
          }
        }
      },
    },
    '& .profileAvatarWrap': {
      '& .MuiTouchRipple-root': {
        display: 'none'
      },
      '& .profileAvatarLabel': {
        '&:hover': {
          transition: '0.4s',
          '& .overlayBox, .removeBtn': {
            opacity: 1,
            transition: '0.4s',
          },
        }
      },
      '& .overlayBox': {
        position: 'absolute',
        background: '#0000007d',
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: themePallete.primary.contrastText,
        opacity: 0,
        transition: '0.4s',
      },
      '& .removeBtn': {
        position: 'absolute',
        bottom: 0,
        right: 0,
        padding: 5,
        background: themePallete.primary.contrastText,
        color: themePallete.error.main,
        opacity: 0,
        transition: '0.4s',
        '& svg': {
          fontSize: 20,
        }
      }
    },
    '& .MuiDivider-root': {
      borderColor: themePallete.text.light,
    },
    '& .bigWidthBtn': {
      padding: '8px 15px',
      fontSize: 16,
      minWidth: 180,
      [Theme.breakpoints.up('sm')]: {
        minWidth: 250,
      },
      [Theme.breakpoints.up(1700)]: {
        minWidth: 300,
        padding: '8px 35px',
        fontSize: 20,
      },
    },
    // start img uploader
    '& .imgUploaderWrap': {
      width: 190,
      height: 190,
      margin: '0 auto',
      borderRadius: '5px',
      position: 'relative',
      '& input': {
        opacity: 0,
        display: 'block !important',
        width: '100%',
        height: '100%',
        zIndex: 1,
      },
      '& label': {
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        flexDirection: 'column',
        maxWidth: '100%',
        padding: 0,
        backgroundColor: 'transparent !important',
        '& img': {
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: '5px',
          position: 'absolute',
          marginRight: 0,
          filter: 'unset !important',
        },
      },
      '& .imgUploaderHeading': {
        position: 'absolute',
        zIndex: 1,
        width: '100%',
        height: '100%',
        borderRadius: '5px',
        background: '#00000052',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        '& svg': {
          backgroundColor: themePallete.secondary.main,
          borderRadius: '50%',
          height: 32,
          width: 32,
          padding: 8,
          minWidth: 'auto',
          color: '#00000052',
          fontSize: 22,
        },
        '& .MuiTypography-root': {
          textDecoration: 'underline',
          textTransform: 'initial',
          color: themePallete.secondary.main,
          textAlign: 'center'
        }
      },
      '& .closeIcon': {
        position: 'absolute',
        top: 10,
        right: 10,
        backgroundColor: themePallete.secondary.main,
        color: themePallete.error.main,
        borderRadius: '50%',
        height: 28,
        width: 28,
        padding: 5,
        minWidth: 'auto',
        zIndex: 2,
        transition: '0.4s',
        boxShadow: '0 0 10px #00000030',
        '& svg': {
          fontSize: 20,
        },
        '&:hover': {
          boxShadow: '0 0 8px #0000008f',
          transition: '0.4s',
        }
      },
      '& .MuiTouchRipple-root': {
        display: 'none',
      },
    }, // end img uploader
    '& .p20': {
      padding: '20px !important',
    },
    '& .p30': {
      padding: '30px !important',
    },
    '& .p50': {
      padding: '50px !important',
    },
    '& .MuiTabs-root': {
      minHeight: 'auto',
      '& .MuiTab-root': {
        padding: '2px 0 15px 0',
        minHeight: 'auto',
        marginRight: 25,
        color: themePallete.text.main,
        fontFamily: openSans,
        fontWeight: 600,
        '&.Mui-selected': {
          color: themePallete.primary.main,
        },
        '& .MuiTouchRipple-root': {
          display: 'none',
        }
      },
    },
    '& .tabTableMain': {
      '& .tabTableHeader': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderBottom: '1px solid #d3d3d3',
        margin: '0 0 20px -18px',
        width: 'calc(100% + 36px)',
        padding: '0 20px 20px 20px',
        [Theme.breakpoints.down(992)]: {
          flexDirection: 'column-reverse',
          alignItems: 'flex-start',
          padding: '0 15px 20px 15px',
          width: 'calc(100% + 30px)',
          margin: '0 0 20px -15px',
        },
        '& .tabTableHeaderLft': {
          maxWidth: '50%',
          display: 'flex',
          alignItems: 'center',
          [Theme.breakpoints.down(992)]: {
            maxWidth: '100%',
            width: '100%',
            justifyContent: 'center',
          },
          [Theme.breakpoints.down(576)]: {
            flexDirection: 'column-reverse',
          },
        },
        '& .MuiTabs-root': {
          marginBottom: 0,
          [Theme.breakpoints.down(576)]: {
            width: '100%',
            marginTop: 15,
          },
          '& .MuiTab-root': {
            marginRight: 20,
            minWidth: 'auto',
            padding: '10px 5px 12px 5px',
          },
          '& .MuiTabScrollButton-root': {
            display: 'flex',
            [Theme.breakpoints.down(992)]: {
              width: 20,
            },
            '& svg': {
              color: themePallete.secondary.dark,
              fontSize: 22,
            },
            '&.Mui-disabled': {
              opacity: 0.5,
            }
          }
        },
        '& .tabTableHeadRight': {
          display: 'flex',
          justifyContent: 'flex-end',
          maxWidth: '50%',
          paddingLeft: 30,
          [Theme.breakpoints.down(992)]: {
            paddingLeft: 0,
            paddingBottom: 30,
            maxWidth: '100%',
            width: '100%',
          },
        },
        '& .datepickerWrap': {
          width: 190,
        },
      },
    },
    '& .searchToggleWrap': {
      position: 'relative',
      '& button': {
        border: '1px solid #0000003b',
        backgroundColor: '#F3F6F9',
      },
      '& .textFieldMainWrap': {
        position: 'absolute',
        top: 50,
        right: 0,
        width: 400,
        background: themePallete.secondary.main,
        boxShadow: '0 0 15px #00000036',
        padding: 12,
        zIndex: 5,
        borderRadius: '5px',
        [Theme.breakpoints.down(992)]: {
          width: 300,
          right: -125,
        },
        [Theme.breakpoints.down(576)]: {
          width: 260,
          right: -200,
        },
        '& .MuiFormControl-root': {
          marginBottom: 0,
        }
      }
    },
    // **** end common css ****

    // **** start header css ****
    '& .dashboardHeaderWrap': {
      backgroundColor: themePallete.secondary.main,
      boxShadow: '0 0 12px #00000021',
      [Theme.breakpoints.down('md')]: {
        marginLeft: 0,
        width: '100%',
      },
      '& .dashHeaderInnerMain': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        padding: '6px 0',
        '& .headerLeft': {
          display: 'flex',
          alignItems: 'center',
        },
        '& .headerRight': {
          display: 'flex',
          alignItems: 'center',
        },
        '& button': {
          fontSize: 14,
          // padding: '7px 15px',
        }
      },
    },
    // **** end header css ****

    // **** start Dashboard Contents css ****
    '& .dashboardLayoutMain': {
      // maxWidth: 'calc(100% - 270px)',
    },
    '& .dashboardContentMainWrap': {
      padding: '25px 20px 20px',
      position: 'relative',
      [Theme.breakpoints.down('sm')]: {
        padding: '60px 20px 20px',
      },
      '& .heading': {
        textAlign: 'center',
        margin: '0 auto',
        marginBottom: 30,
        [Theme.breakpoints.up('md')]: {
          width: '90%',
        },
        [Theme.breakpoints.up(1700)]: {
          width: '80%',
        },
        '& h3': {
          fontFamily: openSans,
          fontWeight: 600,
          marginBottom: 12,
        },
        '&.full': {
          width: '100% !important',
        }
      },
      '& .whiteBox': {
        backgroundColor: themePallete.secondary.main,
        borderRadius: '10px',
        boxShadow: '0 4px 22px 0 rgb(0 0 0 / 6%)',
        padding: 18,
        [Theme.breakpoints.down(992)]: {
          padding: 15,
        },
      },
      '& .lightBlueBox': {
        backgroundColor: themePallete.secondary.contrastText,
        borderRadius: '10px',
        padding: 18,
        [Theme.breakpoints.down(992)]: {
          padding: 15,
        },
      },
      '& .switchListWrap': {
        '& li': {
          borderBottom: `1px solid ${themePallete.primary.contrastText}`,
          padding: '10px 0',
          lineHeight: 'initial',
          '&:last-of-type': {
            borderBottom: 'none',
            paddingBottom: 0,
          },
          '&.MuiListSubheader-root': {
            borderBottom: 'none',
            paddingTop: 0,
          }
        }
      },
      '& .customButtonGroup': {
        '& button': {
          fontSize: 15,
          lineHeight: 'initial',
          '&:first-of-type': {
            borderRadius: '5px 0 0 5px',
          },
          '&:last-of-type': {
            borderRadius: '0 5px 5px 0',
          }
        }
      },
      '& .monthYearTab': {
        width: 'fit-content',
        margin: '0 auto 50px auto',
        border: '1px solid #d7d7d7',
        padding: 5,
        background: themePallete.secondary.main,
        borderRadius: '8px',
        '& button': {
          backgroundColor: 'transparent',
          borderRadius: '0 !important',
          minWidth: 100,
          color: `${themePallete.secondary.dark} !important`,
          [Theme.breakpoints.up(1600)]: {
            fontSize: '18px !important',
          },
          '&:first-of-type': {
            marginLeft: 0,
          },
          '&:last-of-type': {
            marginRight: 0,
          },
          '& .MuiTouchRipple-root': {
            display: 'none'
          },
          '&.Mui-disabled': {
            color: themePallete.secondary.dark,
            backgroundColor: themePallete.primary.main,
            transition: '0.4s',
            borderRadius: '5px !important',
          }
        }
      },
      '& $dashboardPg': {
        '& .whiteBox': {
          padding: 50,
          [Theme.breakpoints.down('sm')]: {
            padding: '30px 15px',
          },
        }
      },
      '& .buyerSellerSwitchMain': {
        background: themePallete.secondary.main,
        boxShadow: '0 0 12px #00000021',
        marginBottom: 10,
        position: 'sticky',
        top: 66,
        padding: '10px 20px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        zIndex: 9,
        '& button': {
          fontSize: 14,
          fontWeight: 500,
          textTransform: 'capitalize',
          fontFamily: "'Open Sans',sans-serif",
          padding: '6px 10px',
          '&.Mui-selected': {
            color: themePallete.text.main,
          },
          [Theme.breakpoints.down(992)]: {
            fontSize: 13,
          },
        }
      }
    },
    // **** end Dashboard Contents css ****
  },
  // ========================== End Dashboard Layouts css ==========================

  // ====================== start pricing pages ======================
  plansListPg: {
    '& .customButtonGroup': {
      [Theme.breakpoints.down('sm')]: {
        marginBottom: 35,
      },
    },
    '& .pricingOptionBoxGridCol': {
      '&:nth-of-type(2)': {
        '& .pricingOptionHeader': {
          backgroundColor: '#cfd9ecbd',
        },
        '& .pricingOptionBoxes': {
          marginTop: -20,
          height: 'calc(100% + 40px)',
          [Theme.breakpoints.down('md')]: {
            marginTop: 0,
            height: '100%',
          },
          '&:hover': {
            '& .pricingOptionBoxContent': {
              backgroundColor: '#cfd9ecbd',
            }
          },
        }
      },
      '&:nth-of-type(3)': {
        '& .pricingOptionHeader': {
          backgroundColor: '#edf9fb',
        },
        '& .pricingOptionBoxes': {
          '&:hover': {
            '& .pricingOptionBoxContent': {
              backgroundColor: '#edf9fb',
            }
          },
        }
      },
      '&.singlePlan': {
        '&:nth-of-type(2)': {
          '& .pricingOptionBoxes': {
            marginTop: 0,
            height: '100%',
          }
        }
      }
    },
    '& .pricingOptionBoxes': {
      border: `solid 2px ${themePallete.primary.dark}`,
      padding: '5px',
      position: 'relative',
      borderRadius: '18px',
      backgroundColor: themePallete.secondary.main,
      height: '100%',
      '&:hover': {
        '& .pricingOptionBoxContent': {
          transition: '0.5s',
          backgroundColor: '#feeab7',
        },
        '& .pricingOptionHeader': {
          borderBottom: '1px solid #0000002e',
        }
      },
      '& .pricingTags': {
        top: -10,
        right: 15,
        width: 80,
        height: 80,
        position: 'absolute',
        zIndex: 1,
        [Theme.breakpoints.up(1600)]: {
          width: 105,
        },
        [Theme.breakpoints.up(1800)]: {
          width: 118,
        },
        '& img': {
          width: '100%',
        },
        '& .pricingOptionBoxPrice': {
          top: 12,
          left: 12,
          color: themePallete.secondary.main,
          right: 5,
          position: 'absolute',
          textAlign: 'left',
          [Theme.breakpoints.up(1600)]: {
            left: 15,
          },
          [Theme.breakpoints.up(1800)]: {
            left: 16,
          },
          '& h4': {
            margin: '0 0 3px 0',
            fontSize: 16,
            fontWeight: 700,
            color: themePallete.secondary.main,
            [Theme.breakpoints.up(1600)]: {
              fontSize: 22,
            },
            [Theme.breakpoints.up(1800)]: {
              fontSize: 25,
            },
          },
          '& p': {
            margin: 0,
            fontSize: 10,
            color: themePallete.secondary.main,
            [Theme.breakpoints.up(1600)]: {
              fontSize: 14,
            },
            [Theme.breakpoints.up(1800)]: {
              fontSize: 16,
            },
          }
        },
        '& .priceTagBadge': {
          padding: 5,
          margin: '-12px 0 0 0',
          width: 69,
          position: 'relative',
          minHeight: 55,
          [Theme.breakpoints.up(1600)]: {
            width: 79,
          },
          [Theme.breakpoints.up(1800)]: {
            width: 96,
          },
          '& .badgeBottomArrow': {
            borderLeft: '35px solid transparent',
            borderTopWidth: 12,
            borderTopStyle: 'solid',
            borderRight: '34px solid transparent',
            width: '100%',
            height: 'auto',
            display: 'inherit',
            position: 'absolute',
            bottom: -11,
            left: 0,
            right: 0,
            [Theme.breakpoints.up(1600)]: {
              borderLeft: '40px solid transparent',
              borderRight: '40px solid transparent',
            },
            [Theme.breakpoints.up(1800)]: {
              borderLeft: '48px solid transparent',
              borderRight: '48px solid transparent',
              borderTopWidth: 15,
              bottom: -14,
            },
          },
          '& .badgeTopArrow': {
            position: 'absolute',
            left: -7,
            top: 0,
            width: 1,
            height: 0,
            borderRightWidth: 8,
            borderRightStyle: 'solid',
            borderTop: '8px solid transparent',
            [Theme.breakpoints.up(1600)]: {
              left: -7,
            },
          }
        }
      },
      '& .pricingOptionHeader': {
        transition: '0.5s',
        borderBottom: '1px solid transparent',
        backgroundColor: '#feeab7',
        '& div': {
          width: 'calc(100% - 95px)',
          padding: '15px 0 15px 15px',
          [Theme.breakpoints.up(1600)]: {
            padding: 20,
          },
        },
        '& h3': {
          margin: '0 0 5px 0',
          fontSize: 17,
          fontWeight: 'bold',
          textTransform: 'capitalize',
          color: themePallete.secondary.dark,
          [Theme.breakpoints.up(1600)]: {
            fontSize: 20,
          },
          [Theme.breakpoints.up(1800)]: {
            fontSize: 25,
          },
        },
        '& p': {
          margin: '0 0 5px 0',
          fontSize: 16,
          fontWeight: 400,
          textTransform: 'capitalize',
          color: themePallete.text.light,
          fontFamily: mulish,
          [Theme.breakpoints.up(1600)]: {
            fontSize: 18,
          },
          [Theme.breakpoints.up(1800)]: {
            fontSize: 22,
          },
        }
      },
      '& .pricingOptionBoxContent': {
        overflow: 'hidden',
        transition: '0.5s',
        borderRadius: '18px',
        color: themePallete.secondary.dark,
        height: '100%',
        fontFamily: mulish,
        position: 'relative',
        '& .pricingOptionContent': {
          padding: '20px 15px',
          [Theme.breakpoints.up(1600)]: {
            padding: '25px 20px 20px',
          },
          [Theme.breakpoints.down('md')]: {
            padding: '15px 8px',
          },
        },
        '& ul': {
          margin: 0,
          padding: '0 0 150px 0',
          listStyle: 'none',
          [Theme.breakpoints.up(1600)]: {
            padding: '0 0 190px 0',
          },
          [Theme.breakpoints.down('md')]: {
            padding: '0 0 100px 0',
          },
          [Theme.breakpoints.down('sm')]: {
            padding: '0 0 30px 0',
          },
          '& li': {
            position: 'relative',
            padding: '0 0 0 22px',
            margin: '0 0 12px 0',
            [Theme.breakpoints.up(1600)]: {
              fontSize: 20,
              padding: '0 0 0 32px',
              margin: '0 0 20px 0',
            },
            [Theme.breakpoints.up(1800)]: {
              fontSize: 22,
            },
            '&:last-of-type': {
              margin: 0,
            },
            '&:before': {
              content: "''",
              position: 'absolute',
              top: 7,
              left: 0,
              height: 12,
              width: 12,
              backgroundImage: `url(${BlackDot})`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              [Theme.breakpoints.up(1600)]: {
                height: 16,
                width: 16,
              },
            }
          },
        },
        '& .pricingButtonWrap': {
          // display: 'flex',
          // justifyContent: 'space-between',
          // '& button': {
          //   width: '50%',
          //   margin: '0 5px',
          // }
        },
        '& button': {
          fontSize: 15,
          borderRadius: '8px',
          maxWidth: 'inherit',
          [Theme.breakpoints.up(1600)]: {
            fontSize: 20,
          },
          [Theme.breakpoints.up(1800)]: {
            fontSize: 22,
          },
          [Theme.breakpoints.down('md')]: {
            fontSize: 14,
          },
        }
      },
      '& .pricingOptionBxBottomCntnt': {
        left: 0,
        right: 0,
        width: 'calc(100% - 30px)',
        bottom: 30,
        margin: '0 auto',
        position: 'absolute',
        [Theme.breakpoints.down('md')]: {
          width: 'calc(100% - 20px)',
        },
        [Theme.breakpoints.down('sm')]: {
          width: '100%',
          position: 'relative',
          bottom: 0,
        },
      }
    },
  }, //end plans list pages

  paymentPagesMain: {
    '& .paymentHeadingBox': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: 15,
      [Theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    '& .increaseDecraseMainWrap': {
      display: 'flex',
      alignItems: 'center',
      margin: '0 8px',
      [Theme.breakpoints.down('sm')]: {
        margin: '20px 0',
      },
    },
    '& .increaseDecraseBox': {
      display: 'flex',
      alignItems: 'center',
      padding: '4px 8px',
      borderRadius: '50px',
      backgroundColor: themePallete.secondary.contrastText,
      '& p': {
        color: themePallete.secondary.dark,
      },
      '& .MuiFormControl-root': {
        marginBottom: 0,
        '& fieldset': {
          border: 'none',
        },
        '& input': {
          maxWidth: 'fit-content',
          minWidth: 32,
          padding: 0,
          fontSize: 15,
          textAlign: 'center',
          margin: '0 5px',
          color: themePallete.secondary.dark,
        },
      },
      '& button': {
        padding: 0,
        backgroundColor: themePallete.secondary.main,
        '& svg': {
          fontSize: 16,
          color: themePallete.secondary.dark,
        },
        '&.Mui-disabled': {
          opacity: '0.6',
        }
      }
    },
    '& .labelCheckbox': {
      '& .MuiFormControlLabel-root': {
        marginLeft: 0,
        '& .MuiCheckbox-root': {
          padding: 0,
        },
        '& .MuiTypography-root': {
          marginLeft: 5,
          color: '#000',
        }
      }
    },
    '& .applyCouponBox': {
      '& .MuiIconButton-root': {
        transition: '0.4s',
        '&:hover': {
          backgroundColor: themePallete.primary.main,
          transition: '0.4s',
        },
        '& svg': {
          fontSize: 14,
          color: themePallete.secondary.dark,
        },
      },
      '& .MuiButton-root': {
        fontSize: 14,
        padding: 0,
        minWidth: 'auto',
        '&:hover': {
          color: themePallete.primary.main,
        }
      }
    },
    '& .planAmountList': {
      display: 'flex',
      justifyContent: 'space-between',
      [Theme.breakpoints.down(450)]: {
        flexDirection: 'column',
        '& h5': {
          margin: '8px 0 0 0',
        }
      },
    },
    '& .stripeInputField': {
      border: '1px solid #0000003b',
      borderRadius: '6px',
      padding: '14px 15px',
    },
  }, // end payment pages

  paymentStatusPg: {
    '& .paymentStatusContent': {
      [Theme.breakpoints.up(1024)]: {
        width: '65%',
        margin: '0 auto',
        padding: 30,
      },
    },
    '& img': {
      width: 75,
      margin: '0 0 25px 0',
    },
    '& p': {
      color: '#00000080',
    },
    '& button': {
      padding: '8px 15px',
      fontSize: 16,
      minWidth: 180,
      [Theme.breakpoints.up('sm')]: {
        minWidth: 250,
      },
    }
  }, // start payment status page

  preDashboardPg: {
    '& .stepperContentUl': {
      display: 'flex',
      justifyContent: 'center',
      margin: '0 auto',
      [Theme.breakpoints.up(1200)]: {
        maxWidth: '80%',
      },
      [Theme.breakpoints.down(576)]: {
        flexDirection: 'column',
        alignItems: 'center',
      },
      '& .stepBox': {
        textAlign: 'center',
        display: 'block',
        maxWidth: 'fit-content',
        [Theme.breakpoints.down('sm')]: {
          padding: 0,
          '&:nth-of-type(3)': {
            padding: '0 30px',
          }
        },
        [Theme.breakpoints.down(576)]: {
          '&:nth-of-type(3)': {
            padding: '30px 0',
          }
        },
        '& img': {
          width: 55,
          [Theme.breakpoints.up(1600)]: {
            width: 75,
          },
        },
        '& h4': {
          [Theme.breakpoints.down(768)]: {
            fontSize: 16,
          },
        }
      },
      '& .stepStatus': {
        color: '#737373',
        backgroundColor: '#dbe3d9',
        padding: '2px 12px',
        fontSize: 12,
        maxWidth: 'fit-content',
        textAlign: 'center',
        fontWeight: 600,
        borderRadius: '15px',
        display: 'flex',
        alignItems: 'center',
        margin: '0 auto',
        [Theme.breakpoints.up(1600)]: {
          fontSize: 16,
        },
        '&.complete': {
          color: themePallete.success.main,
          backgroundColor: themePallete.success.contrastText,
        },
        '& svg': {
          width: 15,
          marginRight: 4,
          [Theme.breakpoints.up(1600)]: {
            width: 22,
          },
        }
      },
      '& .stepBorderMain': {
        maxWidth: 50,
        [Theme.breakpoints.up(1200)]: {
          maxWidth: 100,
        },
        [Theme.breakpoints.up(1600)]: {
          maxWidth: 130,
        },
        [Theme.breakpoints.down('sm')]: {
          display: 'none',
        },
        '& .stepBorder': {
          width: '100%',
        },
        '& .stepGreenBorder': {
          borderBottom: `2px solid ${themePallete.success.main}`,
        },
        '& .stepGreyDashBorder': {
          borderBottom: `2px dashed ${themePallete.text.light}`,
        },
      }
    },

    // start mac box design
    '& .macBoxMain': {
      borderRadius: '15px',
      backgroundColor: themePallete.secondary.main,
      margin: 30,
      [Theme.breakpoints.down('md')]: {
        margin: '30px 0 0 0',
      },
      '& .macBoxHeader': {
        padding: '12px 15px',
        backgroundColor: '#e7e7e7',
        borderRadius: '15px 15px 0 0',
        '& .macDots': {
          '& span': {
            width: 10,
            height: 10,
            margin: '0 3px',
            display: 'block',
            borderRadius: '50%',
            backgroundColor: themePallete.primary.main,
          }
        }
      },
      '& .macBoxContent': {
        border: `2px dashed ${themePallete.text.light}`,
        display: 'flex',
        padding: '30px 30px 60px 30px',
        borderTop: 'none',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
        textAlign: 'center',
        borderRadius: '0 0 15px 15px',
        '& img': {
          width: 220,
          marginBottom: 40,
          [Theme.breakpoints.down('sm')]: {
            width: 160,
            marginBottom: 25,
          },
        }
      }
    },
    // end mac box design
  }, // start pre-dashboard page

  // start dashboard page
  dashboardPg: {},
  // end dashboard page

  // start theme setup page
  themeSetupPg: {
    '& .themeSetupMainWraper': {
      display: 'flex',
      [Theme.breakpoints.down('sm')]: {
        display: 'block',
      },
      '--var-imageUploader': '220px',
      '& .imgUploaderWrap': {
        width: 'var(--var-imageUploader)',
        margin: '0 30px 0 0',
        height: 100,
        [Theme.breakpoints.down('sm')]: {
          margin: '0 0 30px 0',
        },
        '& input': {
          height: 100,
        },
        '& label': {
          height: 100,
        },
        '& .closeIcon': {
          top: -15,
        },
      },
      '& .themeSetupRightWrap': {
        width: 'calc(100% - var(--var-imageUploader))',
        [Theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
      '& button': {
        minWidth: 170,
        [Theme.breakpoints.down(400)]: {
          width: '100%',
          maxWidth: '100%',
        },
      }
    },
    '& .whiteBox': {
      padding: '50px !important',
      [Theme.breakpoints.down('sm')]: {
        padding: '20px !important',
      },
    }
  },
  // end theme setup page

  // start profile page
  profilePg: {
    '& .profileBanner': {
      backgroundImage: `url(${ProfileBanner})`,
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      padding: '150px 50px',
      position: 'absolute',
      top: headerHeight,
      left: 0,
      right: 0,
      width: '100%',
      [Theme.breakpoints.down('sm')]: {
        top: mobileHeaderHeight,
      }
    },
    '& .profileTopContent': {
      marginTop: 80,
      position: 'relative',
      zIndex: 1,
      '& h1': {
        color: themePallete.secondary.main,
        fontFamily: openSans,
      },
      // '& .whiteBox': {
      //   [Theme.breakpoints.down(400)]: {
      //     padding: '20px !important',
      //   },
      // }
    },
    '& .profileTopInsideCntnt': {
      display: 'flex',
      [Theme.breakpoints.down('sm')]: {
        display: 'block',
      },
      '--var-imageUploader': '220px',
      '& .imgUploaderWrap': {
        width: 'var(--var-imageUploader)',
        margin: '0 30px 0 0',
        [Theme.breakpoints.down('sm')]: {
          margin: '0 0 30px 0',
        },
      },
      '& .profileRightWrap': {
        width: 'calc(100% - var(--var-imageUploader))',
        [Theme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
      '& .MuiButton-root': {
        minWidth: 150,
        [Theme.breakpoints.down(400)]: {
          width: '100%',
          maxWidth: '100%',
        },
      }
    }
  },
  // end profile page

  // ====================== end pricing pages ======================

  // start evaluation dashboard page
  evaluationDashboardPg: {},
  // end evaluation dashboard page

});

export default styles;
