import { MuiBox, MuiButton, MuiFormHelperText, MuiInputField } from 'components/MUI';
import { ArrowForwardIcon } from 'helpers/Icons';
import React, { useState } from 'react';
import CreditCardInput from 'react-credit-card-input';
import { required, whiteSpaceNotAllowedFirstLetter } from 'validators';

function CreditCard(props) {

    const { onSubmit, isTrial } = props;
    const [state, setState] = useState({
        cardNumber: '',
        expiry: '',
        cvc: '',
        name: ''
    })

    const [error, setError] = useState({
        nameError: ''
    })

    const replaceString = val => val.replaceAll(" ", "")

    const { cardNumber, cvc, expiry, name } = state;

    const handleCardChange = (e) => {
        const { value, id } = e.target;
        let type = '';
        if (id === 'card-number') type = 'cardNumber'
        else if (id === 'card-expiry') type = 'expiry'
        else if (id === 'cvc') type = 'cvc';
        setState(prev => ({ ...prev, [type]: value }))
    }

    const handleNameChange = (event) => setState((prev) => ({ ...prev, name: event.target.value }));

    const handleCardClick = () => {
        const errorObject = { nameError: '' };
        setError((prev) => ({ ...prev, nameError: '' }))

        if (required(name) || whiteSpaceNotAllowedFirstLetter(name))
            errorObject.nameError = 'Your name is required. The first letter cannot be a space';

        if (!(cardNumber && cvc && expiry && !errorObject.nameError)) {
            setError(prevState => ({ ...prevState, ...errorObject }))
            return
        }

        const expirtyMonthYear = replaceString(expiry).split("/")

        const data = { number: replaceString(cardNumber), cvc, exp_month: expirtyMonthYear[0], exp_year: expirtyMonthYear[1] };

        if (onSubmit) onSubmit(data);
    }

    return (
        <MuiBox>
            <MuiInputField
                name='name'
                onChange={handleNameChange}
                value={name}
                placeholder="Cardholder name"
            />
            <MuiFormHelperText error={error.nameError ? true : false}>{error.nameError}</MuiFormHelperText>
            <MuiBox display='flex' flexDirection='column' gap="16px">
                <CreditCardInput
                    cardNumberInputProps={{ value: cardNumber, onChange: handleCardChange }}
                    cardExpiryInputProps={{ value: expiry, onChange: handleCardChange }}
                    cardCVCInputProps={{ value: cvc, onChange: handleCardChange }}
                />

                <MuiButton
                    onClick={handleCardClick}
                    width='full'
                    iconposition='right'
                    endIcon={<ArrowForwardIcon />}
                >
                    {isTrial ? 'Add Card & Pay' : 'Add Card'}
                </MuiButton>
                {/* <button onClick={handleCardClick} className='btn btn-primary'>Add Card</button> */}
            </MuiBox>
        </MuiBox>
    )
}

export default CreditCard