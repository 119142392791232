import React from 'react';
import PropTypes from 'prop-types';
import { Menu, IconButton, List, ListItem, ListItemText, Divider, ListItemAvatar, Avatar, Typography, Box } from '@mui/material';
// import { NotificationsIcon } from 'helpers/Icons';
import WorkIcon from '@mui/icons-material/Work';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { MuiBadge } from "components/MUI";
import moment from 'moment';

const NotificationMenu = ({ notifications }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (notifications.length > 0)
      setAnchorEl(event.currentTarget);
  }
  const handleClose = () => setAnchorEl(null);
  return (
    <div>
      <IconButton 
        onClick={handleClick} 
        color="primary"
        aria-controls="notification-menu"
        aria-haspopup="true"
      >
        <MuiBadge color="warning" badgeContent={notifications.length}>
          <NotificationsNoneIcon sx={{ fontSize: '35px' }}/>
        </MuiBadge>
      </IconButton>
      <Menu
        id="notification-menu"
        anchorEl={anchorEl}
        onClose={handleClose}
        open={Boolean(anchorEl)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper', p: 0 }}>
          {notifications.map((notif, notiKey) => {
            return ( 
            <>
              <ListItem alignItems="flex-start" key={`notif_${notif.id}`} onClick={handleClose} sx={{ cursor: 'pointer' }}>
                <ListItemAvatar>
                  <Avatar sx={{ bgcolor: 'primary.main', width: 44, height: 44 }}>
                    <WorkIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontSize: '16px' }}
                      component="div"
                      variant="body2"
                      color="text.primary"
                    >
                      {notif.data.message}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      style={{ fontSize: '14px' }}
                      component="div"
                      variant="body2"
                      color="text.primary"
                    >
                      {moment(notif.created_at).format('Do MMMM, YYYY')} at {moment(notif.created_at).format('h:mm A')}
                    </Typography>
                  }
                />
              </ListItem>
              {notiKey !== notifications.length - 1 && <Divider variant="inset" component="li" />}
            </>
            )
          })}
        </List>
        {/* {notifications.map((notif, notiKey) => <MenuItem key={notiKey} onClick={handleClose}>{notif.data.message}</MenuItem>)} */}
      </Menu>
    </div>
  );
};


NotificationMenu.propTypes = {
  notifications: PropTypes.array,
};
NotificationMenu.defaultPropTypes = {
  notifications: []
}
export default NotificationMenu;
