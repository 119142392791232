import React from 'react'
import { ToastContainer } from 'react-toastify';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';

function ToastPortal(props) {

    return (
        ReactDOM.createPortal(props.children,
            document.getElementById("toastPortal"))
    );
}

function ToastContent() {
    return (
        <>
            <ToastContainer
                position='top-right'
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </>
    )
}

export { ToastContent, ToastPortal }