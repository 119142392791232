import { combineReducers } from '@reduxjs/toolkit';
import Login from './login';
import Subscription from './subscription';
import Ui from './ui';

const Reducers = combineReducers({
    login: Login,
    subscribtion: Subscription,
    ui: Ui
})

export default Reducers;