import React from "react";
import { MuiBox, MuiTooltip,MuiTypography,MuiCircularProgress } from "components/MUI";
import { connect, useDispatch } from "react-redux";
import { setLogout } from "redux/Actions/login";
import AuthService from "services/AuthService";
import { NotificationMenu, UserMenu } from "components";
import { setResetCard, setResetPlan } from "redux/Actions/subscription";
import { Button } from "@mui/material";

function Header(props) {

    const { user,domainLoader } = props;
    const dispatch = useDispatch();
    const rootDomain = process.env.NODE_ENV === 'development' ? 'vockam.bert' : window.location.host
    const shouldShowDomain = !['canceled', 'none'].includes(user?.subscription_status);

    const handleLogout = () => {
        AuthService.logout().then((resp) => {
            if (resp.status === 'success') {
                dispatch(setLogout());
                dispatch(setResetCard());
                dispatch(setResetPlan());
            }
        }).catch((err) => {
            return err;
        })
    }

    const handleRedirectDomain = () => {
        AuthService.impersonateUrl().then((resp) => {
            if (resp.status === 'success') {
                window.open(resp.data, "_blank");
            }
        })

    }

    return (
        <MuiBox className='dashHeaderInnerMain'>
            <MuiBox className='headerLeft'>
                {domainLoader && !shouldShowDomain && (
                <React.Fragment>
                    <MuiCircularProgress size={24} style={{ color: "#007bff" }} />
                    <MuiTypography variant="body1" color="textPrimary">
                        Your custom domain portal is being built.Please wait, this may take a few moments.
                    </MuiTypography>
                </React.Fragment>)}
            </MuiBox>
            <MuiBox className='headerRight'>
                {shouldShowDomain && (
                    <MuiTooltip title="Go to url">
                        <Button onClick={handleRedirectDomain} variant="text" disableRipple>
                        {`${user?.domain}.${rootDomain}`}                               
                        </Button>
                    </MuiTooltip>
                )}
                <NotificationMenu notifications={user.notifications ? user.notifications : []} />
                <UserMenu user={user} handleLogout={handleLogout} />
            </MuiBox>
        </MuiBox>
    );
}

const mapStateToProps = (state) => ({
    userStatus: state.login.userStatus,
    user: state.login.user,
    domainLoader: state.login.domainLoader
})

export default connect(mapStateToProps)(Header);
