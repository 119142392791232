import { connect } from 'react-redux';
import 'App.css';
import Auth from './layouts/Auth';
import { Switch, Route } from 'react-router-dom';
import { UserStatus } from './redux/Reducers/login';
import Store from './redux/Store';
import { setCheckAuth } from './redux/Actions/login';
import Theme from './Theme';
import { ThemeProvider } from '@mui/material';
import { withStyles } from '@mui/styles';
import styles from './global-styles';
import Application from 'layouts/Application';
import { ZohoSalesIO } from 'components';
import { StaticRoutes } from 'routes/Auth';
import { Map } from 'helpers';

Store.dispatch(setCheckAuth());
function App(props) {

  const { isAuthenticated, userStatus } = props;

  return (
    <ThemeProvider theme={Theme}>
      <Switch>
        {Map(StaticRoutes, (route, routeIndex) => (
              <Route key={routeIndex} path={route.path} exact={route.exact} render={(rProps) => <route.component {...props} {...rProps} />} />
        ))}
          

        {!isAuthenticated && <Route path='/' render={(rProps) => <Auth {...rProps} {...props} />} />}
        {isAuthenticated && userStatus === UserStatus.VERIFIED &&
          <Route path='/' render={(rProps) => <Application {...props} {...rProps} />} />
        }
      </Switch>
      <ZohoSalesIO />
    </ThemeProvider>
  );
}


const mapStateToProps = (state) => ({
  user: state.login.user,
  isAuthenticated: state.login.isAuthenticated,
  token: state.login.token,
  userStatus: state.login.userStatus,
  plan: state.subscribtion.plan,
  paymentStatus: state.subscribtion.paymentStatus,
  isApiCallProgress: state.ui.isApiCallProgress,
  shouldReloadPage: state.ui.shouldReloadPage
})
const StyleMappedApp = connect(mapStateToProps)(App);

export default withStyles(styles)(StyleMappedApp);
