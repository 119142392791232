import * as yup from "yup";
import * as CompanyEmailValidator from 'company-email-validator';

const EMAIL = yup
    .string()
    .trim()
    //eslint-disable-next-line
    // .matches(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, "Enter a valid email")
    .required("Email is required")
    // .max(150, "Email must be maximum 150 characters")
    .test('validateEmail', 'Enter a valid company email', value => {
        if (value) {
            return CompanyEmailValidator.isCompanyEmail(value);
        }
        return false;
    });

const PASSWORD = (name = 'Password') => yup
    .string()
    .trim()
    .required(`${name} is required`)
    // .min(6, `${name} should have minimun 6 characters`)
    // .max(16, `${name} should have maximum 16 characters`);
    // .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{6,16}$/, 'Password must be minimum 6 and maximum 16 characters and include at least one uppercase and lowercase character, and a number and a special character')

const CONFIRM_PASSWORD = yup
    .string()
    .trim()
    .required("Confirm password is required")
    .oneOf([yup.ref('password'), null], "Both passwords must match");

const NAME_REQUIRED = (type, max = 100, min = 1) => yup
    .string()
    .trim()
    .required(`${type} is required`)
    .min(min, `${type} should have minimun ${min} characters`)
    .max(max, `${type} should have maximum ${max} characters`);

const COMPANY_NAME = yup.string().trim().required('Company name is required');

const DOMAIN = yup
    .string()
    .trim()
    .required('Domain is required')
    .matches(/^[a-zA-Z0-9][a-zA-Z0-9.-]*$/, 'Domain should contain a-z, A-Z, 0-9 $ - .')
    .min(3, 'Domain should have minimun 3 characters')
    .max(100, 'Domain should have maximum 100 characters');

export const LoginValidator = () => yup.object({
    email: EMAIL,
    password: PASSWORD(),
    rememberMe: yup.boolean().required()
});

export const RegisterValidator = () => yup.object({
    name: NAME_REQUIRED('Name'),
    company_name: COMPANY_NAME,
    email: EMAIL,
    domain: DOMAIN,
    password: PASSWORD(),
    vat: yup.string().trim().min(3, 'VAT should have minimun 3 characters').max(255, 'VAT should have maximum 100 characters'),
    isTermsAndConditionChecked: yup.boolean().oneOf([true], 'Please accept terms and conditions')
})

export const ResetPasswordValidator = () => yup.object({
    password: PASSWORD(),
    // confirmPassword: CONFIRM_PASSWORD
})

export const ForgotPasswordValidator = () => yup.object({
    email: EMAIL
})

export const ChangePasswordValidator = () => yup.object({
    oldPassword: PASSWORD('Old password'),
    password: PASSWORD('New password'),
    // confirmNewPassword: CONFIRM_PASSWORD
})

export const ProfileValidator = () => yup.object({
    name: yup.string().required('Name is required'),
    companyName: yup.string().required('Company name is required'),
    email: EMAIL,
    domain: DOMAIN,
    vat: yup.string().trim().min(3, 'VAT should have minimun 3 characters').max(255, 'VAT should have maximum 100 characters')
})

export const NgoValidator = () => yup.object({
    name: yup.string().required('Name is required'),
    phone: yup.string().required('Phone is required'),
    email: EMAIL,
})