import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import { MuiBox, MuiTooltip } from 'components/MUI';
import { InfoOutlinedIcon } from 'helpers/Icons';
import { ClickAwayListener, tooltipClasses } from "@mui/material";
import Theme from 'Theme';

const HtmlTooltip = styled(({ className, ...props }) => (<MuiTooltip {...props} classes={{ popper: className }} />))
    (({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            '&::before': {
                color: Theme.palette.secondary.main
            }
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: Theme.palette.secondary.main,
            color: Theme.palette.secondary.main,
            maxWidth: 400,
            fontSize: theme.typography.pxToRem(12),
            boxShadow: '0 0 20px #00000029',
            padding: 15,
            '& h2': {
                color: '#000',
                fontSize: 14,
                lineHeight: '18px',
                fontWeight: 600,
                marginBottom: 5,
            },
            '& p': {
                color: '#0000009e',
                fontSize: 14,
                marginBottom: 5,
                fontWeight: 400,
            },
            '& a': {
                color: '#fbce17',
                fontWeight: 600,
                textDecoration: 'none',
                '&:hover': {
                    color: '#000'
                }
            },
            '& ul': {
                padding: 0,
                margin: '10px 0',
                '& li': {
                    color: '#0000009e',
                    fontSize: 14,
                    fontWeight: 400,
                    padding: '0 0 8px 0',
                    display: 'flex',
                    '&:last-child': {
                        paddingBottom: 0,
                    },
                    '& svg': {
                        color: '#10d630',
                        fontSize: 14,
                        margin: '1px 5px 0 0'
                    }
                }
            },
        },
    }));

const useWindowWide = (size) => {
    const [width, setWidth] = useState(0)

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth)
        }

        window.addEventListener("resize", handleResize)

        handleResize()

        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [setWidth])

    return width
}

function CustomHoverInfo(props) {

    const { infoIcon = InfoOutlinedIcon, content, placement = "right-start", iconStyle, style = {} } = props;

    const windowWidth = useWindowWide(1200)
    const [open, setOpen] = useState(false);

    const handleTooltipClose = () => {
        setOpen(false);
    };

    const handleTooltipOpen = () => {
        setOpen(true);
    };

    return (
        <>
            {windowWidth >= 1200 ?
                <HtmlTooltip arrow interactive={'true'} placement={placement} title={content}>
                    <MuiBox component='span' className='infoIconWrap' style={style}>
                        {React.createElement(infoIcon, { style: iconStyle })}
                    </MuiBox>
                </HtmlTooltip>
                :
                <ClickAwayListener onClickAway={() => handleTooltipClose()}>
                    <MuiBox component='span'>
                        <HtmlTooltip
                            PopperProps={{
                                disablePortal: true,
                            }}
                            onClose={() => handleTooltipClose()}
                            open={open}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            arrow
                            interactive={'true'}
                            placement={placement}
                            title={content}
                        >
                            <MuiBox component='span' className='infoIconWrap' onClick={() => handleTooltipOpen()} style={style}>
                                {React.createElement(infoIcon, { style: iconStyle })}
                            </MuiBox>
                        </HtmlTooltip>
                    </MuiBox>
                </ClickAwayListener>
            }
        </>
    );
}

export default CustomHoverInfo;
